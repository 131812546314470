.contenidosRegistroVendedor {
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: 2vh;
  /* padding-top: 10vh; */
  color: rgb(226, 153, 45);
  background-image: url('../assets/fondo.png');
  font-family: BrandonGrotesque-Black;
  /* font-family: BrandonGrotesque-Bold; */
  /* font-family: BrandonGrotesque-BoldItalic; */
}

.loading {
  margin: auto;
  width: 9%;
  padding: 20px;
}


.block {
  position: relative;
  /* margin: 300px auto 0; */
  width: fit-content;
  font-size: 8vh;
  /* height: 250px; */
  background: linear-gradient(0deg, transparent, transparent);
}

.block:before,
.block:after {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  background: linear-gradient(45deg,
      /* #102a2e, */
      #4d605c,
      #ffffff,
      #d79d45
      /* #fb0094, */
      /* #0000ff,
    #00ff00,
    #ffff00, */
      /* #ff0000,
    #fb0094, */
      /* #0000ff,
    #00ff00,
    #ffff00 */
      /* #ff0000 */
    );
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: steam 20s linear infinite;
  border-radius: 10%;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.block:after {
  filter: blur(50px);
}

.container_row {
  display: grid;
  margin: auto;
  width: 100%;
}

.layer1,
.layer2 {
  grid-column: 1;
  grid-row: 1;
}

.mario {
  transform: translateY(100%);
  animation: mover 1s;

  animation-direction: normal;
}

@keyframes mover {
  0% {
    transform: translateY(-10);
  }

  25% {
    transform: translateY(-30px);
  }
}

.logoSize {
  width: 40vw;
  height: auto;
}