.centrado {
  position: relative;
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: 2vh;
  /* padding-top: 10vh; */
  color: rgb(226, 153, 45);
  background-image: url("../assets/fondo.png");
  font-family: BrandonGrotesque-Black;
  z-index: 0;
  /* font-family: BrandonGrotesque-Bold; */
  /* font-family: BrandonGrotesque-BoldItalic; */
}

/* .nombres{
    display: flex;
    justify-content: space-evenly;
} */

.mio {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5vw;
}

.fondoCuadrito {
  font-size: 3vh;
  width: 90%;
  margin: auto;
  background-color: rgba(169, 186, 166, 0.4);
}

.contador {
  width: fit-content;
  text-align: center;
  padding: 3px;
  border: 1px;
  border-color: black;
  border-style: dotted;
}

.gift{
 margin-left: 30%;
 width: 40%;
}

.btns {
  width: 32px;
  height: 32px;
}

.btns img {
  width: 32px;
  height: 32px;
  margin: auto;
}

.btns img:active {
  transform: scale(0.8);
}

.block {
  position: relative;
  /* margin: 300px auto 0; */
  width: fit-content;
  font-size: 8vh;
  /* height: 250px; */
  background: linear-gradient(0deg, transparent, transparent);
}

.block:before,
.block:after {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  background: linear-gradient(45deg,
      /* #102a2e, */
      #4d605c,
      #ffffff,
      #d79d45
      /* #fb0094, */
      /* #0000ff,
    #00ff00,
    #ffff00, */
      /* #ff0000,
    #fb0094, */
      /* #0000ff,
    #00ff00,
    #ffff00 */
      /* #ff0000 */
    );
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: steam 20s linear infinite;
  border-radius: 10%;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.block:after {
  filter: blur(50px);
}

.container_row {
  display: grid;
  margin: auto;
  width: 100%;
}

.layer1,
.layer2 {
  grid-column: 1;
  grid-row: 1;
}

.diaSolo {
  width: fit-content;
  height: fit-content;
  border-radius: 5%;
}

.picker {
  color: gray;
  background-color: white;
  border: #ffffff;
  border-radius: 5%;
  height: 5vh;
}

.mario {
  transform: translateY(100%);
  animation: mover 1s;

  animation-direction: normal;
}

@keyframes mover {
  0% {
    transform: translateY(-10);
  }

  25% {
    transform: translateY(-30px);
  }
}

.botonNombres {
  appearance: none;
  background-color: rgba(252, 240, 207);
  border-radius: 40em;
  border-style: none;
  box-shadow: rgba(161, 153, 130) 0 -6px 8px inset;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.24px;
  margin: 0;
  width: 300px;
  outline: none;
  /* padding: 1rem 1.3rem; */
  quotes: auto;
  text-align: center;
  text-decoration: none;
  transition: all 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.botonNombres:hover {
  background-color: rgba(252, 240, 207);
  box-shadow: rgba(161, 153, 130) 0 -6px 8px inset;
  transform: scale(1.125);
}

.botonNombres:active {
  transform: scale(1.025);
}

@media (min-width: 768px) {
  .botonNombres {
    /* font-size: 2vh; */
    padding: 0.75rem 2rem;
  }
}