.loading {
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.contenidosListaNombres {
  width: 100%;
  min-height: 100vh;
  margin: auto;
  /* padding-top: 3vh; */
  text-align: center;
  color: rgb(226, 153, 45);
  background-image: url("../assets/fondo.png");
  font-family: BrandonGrotesque-Black;
}

.vertical {
  position: absolute;
  height: 100vw;
  width: 100vh;

  /* Rotar hacia la derecha */
  /* transform: rotate(90deg);
  transform-origin: bottom left;
  top: -100vw; */

  /* Rotar hacia la izquierda */
  transform: rotate(-90deg) translate(-100%, 0);
  transform-origin: top left;
  bottom: -100vw;
  top: 0px;

}

.tv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* margin-bottom: 10px; */
}

.contenido {
  width: 100vw;
  height: 80vh;
  margin: auto;
  text-align: center;
  color: rgb(226, 153, 45);
  background-image: url("../assets/fondo3.jpeg");
  background-size: 100% 100%;
  /* Estira el fondo para cubrir todo el contenedor */
  background-position: center;
  /* Centra el fondo */
  background-repeat: no-repeat;
  /* No repite el fondo */
  background-attachment: fixed;
  /* Fija el fondo */
  font-family: BrandonGrotesque-Black;
}

.listaNombres {
  font-size: 4vh;
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.titulo {
  font-size: 3vh;
  color: rgb(61, 83, 83);
}

p {
  padding: 1%;
}

.footer1 {
  height: 10vh;
  width: 100%;
  background-color: rgba(99, 112, 228, 0.6);
  font-size: 40px;
  color: black;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer2 {
  height: 10vh;
  width: 100%;
  background-color: rgba(176, 233, 190, 0.6);
  font-size: 40px;
  color: rgb(0, 0, 0);
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video {
  width: 100%;
  height: 80vh;
}