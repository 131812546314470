.nombres {
    border-style: dotted;
    border-color: aquamarine;
    border-radius: 2px;
  }
  
  .loading {
    margin: auto;
    width: 9%;
    padding: 20px;
  }
  