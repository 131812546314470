.centro {
  position: relative;
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: 2vh;
  /* padding-top: 10vh; */
  font-family: BrandonGrotesque-Black;
  z-index: 0;
}

.loading {
  margin: auto;
  width: 9%;
  padding: 20px;
}
