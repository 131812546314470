.btnStripe {
  box-shadow: inset 0px 1px 0px 0px #fff6af;
  background: linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
  background-color: #ffec64;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;
  color: #000000;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 25px;
  font-style: italic;
  font-weight: bold;
  padding: 14px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffee66;
  width: 70%;
  margin: 5%;
  /* max-height: 5vh; */

}

.btnStripe:hover {
  background: linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
  background-color: #ffab23;
}

.btnStripe:active {
  position: relative;
  top: 1px;
}
